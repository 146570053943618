
import { defineComponent } from "vue";
import {
  COMPANY_FETCH_COMPANIES,
  APPLICATION_UPDATE_HMAC_AUTH,
  APPLICATION_SET_NAME,
  SCOPE_FETCH_SCOPES,
} from "@/store-types";
import TreeCheckBox, {
  Item as TreeCheckBoxItem,
} from "@/components/TreeCheckBox.vue";
import { Scope } from "@/interfaces/scope";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

interface Data {
  isSavingApplication: boolean;
  isFetchingApplication: boolean;
  isFetchingScopes: boolean;
}

export default defineComponent({
  components: {
    TreeCheckBox,
  },
  data(): Data {
    return {
      isSavingApplication: false,
      isFetchingApplication: false,
      isFetchingScopes: false,
    };
  },
  async mounted() {
    await this.fetchCompanies();
  },
  computed: {
    applicationName: {
      // getter
      get: function () {
        return this.$store.state.application.hmacAuthApplication?.name;
      },
      // setter
      set: function (name: string) {
        this.$store.commit(APPLICATION_SET_NAME, name);
      },
    },
    companyName(): string | undefined {
      const companyId =
        this.$store.state.application.hmacAuthApplication?.companyId;
      const company = this.$store.state.company.companies.filter(function (
        comp
      ) {
        if (comp.id == companyId) return comp.name;
      });

      return company[0]?.name;
    },
    companyId(): string | undefined {
      return this.$store.state.application.hmacAuthApplication?.companyId;
    },
    scopes(): Array<TreeCheckBoxItem> {
      const selectedScopes: Set<string> = new Set(
        this.$store.state.application.hmacAuthApplication?.scopes || []
      );

      return this.$store.state.scope.scopes.map((scope: Scope) => ({
        ...scope,
        isChecked: selectedScopes.has(scope.name),
      }));
    },
  },
  methods: {
    async fetchCompanies() {
      try {
        await this.$store.dispatch(COMPANY_FETCH_COMPANIES);
      } catch (err) {
        this.handleError(err);
      }
    },
    async fetchScopes() {
      if (this.isFetchingScopes) {
        return;
      }

      this.isFetchingScopes = true;

      try {
        await this.$store.dispatch(SCOPE_FETCH_SCOPES, {
          companyId: this.companyId,
          authType: "hmac_auth",
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingScopes = false;
      }
    },
    async updateHmacAuthApplication() {
      if (this.isSavingApplication) {
        return;
      }

      this.isSavingApplication = true;

      const metadata = {
        CompanySSOID: this.$store.state.application.hmacAuthApplication?.companyId
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Sucess Update Application",
      }
      
      try {
        await this.$store.dispatch(APPLICATION_UPDATE_HMAC_AUTH, {
          uuid: this.$store.state.application.hmacAuthApplication?.id,
          name: this.applicationName,
        });
        mixpanelInstance.setMetadata(metadata);
        emit(event.UpdateApplication, dataEvent);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
        ? (err as any).response.data.message
        : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.UpdateApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.isSavingApplication = false;
      }
    },
    handleError(err: Error | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: err.message,
        type: ToastType.ERROR,
      });
    },
  },
});
